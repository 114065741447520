<template>
    <div style="background-color: #ffffff;">
        <div class="pageCenter text-left m-b-20">
            <div class="f24 bold text-center p-t-30">订单评价</div>
            <!-- <lineTitle text="订单信息"></lineTitle> -->
            <div class="m-b-40 p-l-10 p-r-10">
                <order :orderData="orderData" class="m-t-20" :isShowSelection="true" ref="order"></order>
            </div>
            <!-- <lineTitle text="买家评论"></lineTitle> -->
            <el-form ref="form" :model="form" :rules="formRules" label-width="0px">
                <div class="p-30">
                    <el-row>
                        <el-col :span="10">
                            <div class="title">星级评价</div>
                            <el-rate class="m-t-10" v-model="form.avaluate"></el-rate>
                        </el-col>
                        <el-col :span="14">
                            <div class="">
                                <div class="title">评论内容</div>
                                <el-form-item label="" prop="describer">
                                    <el-input type="textarea" autosize class="m-t-10" :rows="2" placeholder="请输入内容" v-model="form.describer">
                                    </el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="m-t-30">
                        <el-col :span="10">
                            <div class="title">综合评价</div>
                            <div class="m-t-10">
                                <el-radio v-model="form.avaluate" :label="1">非常差</el-radio>
                                <el-radio v-model="form.avaluate" :label="2">差评</el-radio>
                                <el-radio v-model="form.avaluate" :label="3">一般</el-radio>
                                <el-radio v-model="form.avaluate" :label="4">好评</el-radio>
                                <el-radio v-model="form.avaluate" :label="5">非常好</el-radio>
                            </div>
                        </el-col>
                        <el-col :span="14">
                            <div class="title">图片或视频(0/5)</div>
                            <div class="m-t-10">
                                <my-media :srcList="form.myAttaches" :isShowDel="true" @clickDel="clickDel"></my-media>
                                <upload @uploadBack="uploadBack" :multiple="true">
                                    <div class="uploadIcon">
                                        <div>
                                            <i class="el-icon-plus"></i>
                                            <div class="f12 c-2">添加</div>
                                        </div>
                                    </div>
                                </upload>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="text-center m-t-30">
                        <el-button size="mini" type="primary" @click="onSubmit">发表评价</el-button>
                        <el-button size="mini" @click="$oucy.back()">返回</el-button>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { spuComment, schemeOrder } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "comment",
    data() {
        return {
            form: {
                myAttaches: [],
                avaluate: 5, // 1非常差 2差评 3一般 4好评 5非常好
                describer: '',
                orderSchemeSpuId: '',
                isManual: true,
            },
            orderData: {},

            formRules: {
                describer: [
                    { required: true, message: '请输入评论内容', trigger: 'blur' },
                ],
            }
        }
    },
    computed: {

    },
    mounted() {
        this.orderId = this.$route.query.orderId
        this.splitOrder = this.$route.query.splitOrder == 'true' ? true : false
        this.queryOrderDetail()
    },
    methods: {

        // 点击发表评价
        onSubmit() {
            let list = this.$refs.order.multipleSelection
            console.log(list)
            if(list.length==0){
                this.$alert('请勾选要评论的商品')
                return
            }else{
                let orderSchemeSpuIds=[]
                for (let v of list){
                    orderSchemeSpuIds.push(v.id)
                }
            // this.form.orderSchemeSpuIds=orderSchemeSpuIds
                this.form.orderSchemeSpuIds=JSON.stringify(orderSchemeSpuIds)
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.$oucy.keywordFiltr(this.form).then(res=>{
                        this.form.attaches=JSON.stringify(this.form.myAttaches)
                        this.batchComment()
                    },err=>{})

                }
            })
        },
        // 提交到后台
        batchComment() {
            spuComment.batchComment(this.form).then(res => {
                this.$oucy.back()
            })
        },
        // 上传回调
        uploadBack(v) {
            this.form.myAttaches.push(v)
        },
        // 点删除图片
        clickDel({ v, i }) {
            this.form.myAttaches.splice(i, 1)
        },
        // 查询详情
        queryOrderDetail() {
            schemeOrder.queryOrderDetail({ orderId: this.orderId, splitOrder: this.splitOrder }).then(res => {
                this.orderData = res
                // this.detail = res && res.orderSchemes && res.orderSchemes[0]
            })
        },
    }
}
</script>
<style scoped lang="less">
.title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #555C68;
    margin-right: ;
}

.uploadIcon {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 20px 30px;
    color: #aaa;
    // display: inline-block;
    // text-align: center;
    border: 1px dashed #DDDDDD;
}
</style>